export const room=[
	{
		"type":"Deluxe Room",
		"num":101,
		"detail":"1 Bedroom"
	},
	{
		"type":"King Room",
		"num":102,
		"detail":"2 King Size Bed Rooms"
	},
	{
		"type":"Family Room",
		"num":103,
		"detail":"Family Room | 4 Beds |"
	},
	{
		"type": "Deluxe Room",
		"num": 104,
		"detail": "1 Bedroom"
	},
	{
		"type": "King Room",
		"num": 105,
		"detail": "2 King Size Bed Rooms"
	},
	{
		"type": "Family Room",
		"num": 106,
		"detail": "Family Room | 4 Beds |"
	},
	{
		"type": "Deluxe Room",
		"num": 107,
		"detail": "1 Bedroom"
	},
	{
		"type": "King Room",
		"num": 108,
		"detail": "2 King Size Bed Rooms"
	},
	{
		"type": "Family Room",
		"num": 109,
		"detail": "Family Room | 4 Beds |"
	},
	{
		"type": "Deluxe Room",
		"num": 110,
		"detail": "1 Bedroom"
	},
	{
		"type": "King Room",
		"num": 111,
		"detail": "2 King Size Bed Rooms"
	},
	{
		"type": "Family Room",
		"num": 112,
		"detail": "Family Room | 4 Beds |"
	},
	{
		"type": "Deluxe Room",
		"num": 113,
		"detail": "1 Bedroom"
	},
	{
		"type": "King Room",
		"num": 114,
		"detail": "2 King Size Bed Rooms"
	},
	{
		"type": "Family Room",
		"num": 115,
		"detail": "Family Room | 4 Beds |"
	}
]